<template>
  <v-data-table
    class="plans-list-table font-inter mt-6"
    hide-default-footer
    show-select
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    :headers="headers"
    :items="itemsPerView"
    :item-key="itemKey"
    @click:row="handleClickRow"
  >
    <template v-slot:[`headers.name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span class="custom-attribute font-weight-bold">{{ item.name }}</span>
    </template>
    <template v-slot:[`item.priority`]>
      <span class="custom-attribute font-weight-bold">high</span>
    </template>
    <template v-slot:[`item.configurations`]>
      <span>Browsers</span>
    </template>
    <template v-slot:[`item.testruns`]>
      <td class="d-flex align-center">
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold"> 5 test runs</div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.creationdate`]="{ item }">
      <span class="custom-attribute font-weight-bold">{{ formatCreatedAt(item.createdAt)}}</span>
    </template>
    <template v-slot:[`item.tag`]>
      <span class="custom-attribute font-weight-bold">#milestone</span>
    </template>
    <template v-slot:[`item.progress`]="{ item }">
      <div class="progress-container rounded-pill">
        <v-progress-linear
          v-for="(x, index) of progress(item).slice().reverse()"
          :key="index"
          :color="x.color"
          rounded
          background-color="transparent"
          :height="8"
          :value="x.value"
        />
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <div class="d-flex flex-row justify-center">
        <v-menu left offset-y class="font-inter">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense class="text-left font-inter">
            <v-list-item class="pointer d-flex align-center">
              <EditIcon />
              <v-list-item-content @click="onEdit(item)" class="ml-2">{{ $t('edit') }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="pointer">
              <!-- <v-icon>mdi-flag-outline</v-icon> -->
              <AddToMilesonteIcon/>
              <v-list-item-content class="ml-2">{{ $t('plans.addToMilestone') }}</v-list-item-content>
            </v-list-item>
            <v-list-item class="pointer">
              <ArchiveIcon />
              <v-list-item-content class="ml-2">{{ $t('archive') }}</v-list-item-content>
            </v-list-item>
            <v-list-item @click="onDelete(item)" class="pointer">
              <DeleteIcon />
              <v-list-item-content class="ml-2 error--text">{{ $t('delete') }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import DeleteIcon from '@/assets/svg/delete.svg';
import EditIcon from '@/assets/svg/edit.svg';
import ArchiveIcon from '@/assets/svg/archived.svg';
import AddToMilesonteIcon from '@/assets/svg/add-to-milestone.svg';
import { formatDate } from '@/utils/util';

import { testPlansProgressColor } from '@/constants/data.js';

export default {
  name: 'plans-list',

  components: {
    DeleteIcon,
    EditIcon,
    ArchiveIcon,
    AddToMilesonteIcon,
  },
  props: {
    tab: {
      type: String,
      default: 'ongoing',
    },
    data: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    filteredItems: {
      type: Array,
    },
    rowClass: {
      type: Function,
    },
  },
  data() {
    return {
      itemKey: 'name',
      isAllUserChecked: false,
      isSelectedUserChecked: false,
    };
  },
  computed: {
    itemsPerView() {
      if (this.tab === 'ongoing') {
        // return this.data.filter((x) => !x.archived);
        const res = this.data.filter((x) => !x.archived);
        return res;
      } else {
        return this.data.filter((x) => x.archived);
      }
    },
  },
  watch: {
    tab(newValue) {
      this.toggleUsers();
    },
  },
  methods: {
    formatCreatedAt(createdAt) {
      return formatDate(createdAt, 'MM/dd/yyyy')
    },
    onEdit(row) {
      this.$emit('edit:item', row);
    },
    toggleUsers() {
      const isSelected = this.tab === 'ongoing' ? this.isAllUserChecked : this.isSelectedUserChecked;
      this.data.forEach((user) => {
        const condition = this.tab === 'ongoing' ? !user.archived : user.archived;
        if (condition) {
          this.$set(user, 'toBeSelected', isSelected);
        }
      });
    },
    toBeSelectedHandler() {
      const filteredUsers =
        this.tab === 'ongoing' ? this.data.filter((user) => !user.archived) : this.data.filter((user) => user.archived);
      const allTrue = filteredUsers.every((user) => user.toBeSelected);

      if (this.tab === 'ongoing') {
        this.isAllUserChecked = allTrue;
      } else {
        this.isSelectedUserChecked = allTrue;
      }
    },
    handleClickRow(row) {
      this.$emit('click:item', row);
    },
    progress(item) {
      const result = item.progress.map((x) => {
        const color =
          x < 25
            ? testPlansProgressColor[0]
            : x < 50
            ? testPlansProgressColor[1]
            : x < 75
            ? testPlansProgressColor[4]
            : testPlansProgressColor[3];
        return {
          value: x,
          color,
        };
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-container {
  position: relative;
  width: 100%;
  height: 8px;
  background: #f2f4f7;
  overflow: hidden;
}

.v-progress-linear {
  position: absolute;
  left: 0;
}
</style>

<style lang="scss">
.v-progress-linear__determinate {
  border-radius: 4px;
}
</style>
